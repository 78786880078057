import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component{
    render(){
        let title = 'Refreshingly Unique Company About.',
        description = 'At WiseHelix, we provide business and digital solution with innovative ideas to organisations to become more agile in the modern world.',
        description2 = 'We strive to simplify and strengthen the business by Integrating a combination of accelerators to transform their business and achieve efficiency.',
        description3 = 'The automated process enables the business to grow exponentially and expected to be fault-tolerant; it also adapts to continuously changing customer desires, business needs, and technology.',
        description4 = 'We believe an enterprise with the implementation of core business modernisation application can revolutionise their business to deliver the result with agility.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/* <div className="icon">
                                            <FiSend />
                                        </div> */}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <p className="description">{description3}</p>
                                        <p className="description">{description4}</p>
                                        {/* <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div> */}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;