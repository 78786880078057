import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import Breadcrumb from "../elements/common/Breadcrumb";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return (
          <React.Fragment>
            <PageHelmet pageTitle="Contact" />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            <Header
              headerPosition="header--static logoresize"
              logo="all-dark"
              color="color-black"
            />

            {/* Start Breadcrump Area */}
            {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact With Us</h2>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* End Breadcrump Area */}
            {/* Start Breadcrump Area */}
            <Breadcrumb title={"Contact"} />
            {/* End Breadcrump Area */}

            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--120 bg_color--5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title mb--30 text-center">
                      <span className="subtitle">Our contact address</span>
                      <h2 className="title">Quick Contact Address</h2>
                      <p className="description">
                        There are many variations of passages of Lorem Ipsum
                        available, <br /> but the majority have suffered
                        alteration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Start Single Address  */}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="rn-address" style={{ height: "100%" }}>
                      <div className="icon">
                        <FiHeadphones />
                      </div>
                      <div className="inner">
                        <h4 className="title">Contact Phone Number</h4>
                        <p>
                          <a href="tel:+91 98214 63487">+91 98214 63487</a>
                        </p>
                        {/* <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p> */}
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="rn-address" style={{ height: "100%" }}>
                      <div className="icon">
                        <FiMail />
                      </div>
                      <div className="inner">
                        <h4 className="title">Our Email Address</h4>
                        <p>
                          <a href="mailto:info@wisehelix.in">
                            info@wisehelix.in
                          </a>
                        </p>
                        {/* <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> */}
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="rn-address" style={{ height: "100%" }}>
                      <div className="icon">
                        <FiMapPin />
                      </div>
                      <div className="inner">
                        <h4 className="title">Our Location</h4>
                        <p>
                          <b>WiseHelix Systems Private Limited</b>
                          <br />
                          WING N 202 SN53/5/654/1/12, ROHANLEHER II
                          <br />
                          BANER, PUNE 411045 MAHARASHTRA INDIA
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}
                </div>
              </div>
            </div>
            {/* End Contact Top Area  */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--1">
              <ContactTwo />
            </div>
            {/* End Contact Page Area  */}

            {/* Start Contact Map  */}
            {/* <div className="rn-contact-map-area position-relative">
              <div style={{ height: "650px", width: "100%" }}>
                <GoogleMapReact
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
            </div> */}
            {/* End Contact Map  */}

            {/* Start Brand Area */}
            {/* <div className="rn-brand-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </React.Fragment>
        );
    }
}
export default Contact