import React, { Component } from "react";
import {FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Services</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Digital Transformation-02.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">Our service</span>
                                                        <h2 className="title">Digital transformation</h2>
                                                        <p className="description">Digital Transformation is adopting digital technology to transform services or businesses by replacing non-digital or manual processes with digital processes or replacing older digital technology with newer digital technology. Digital solutions may enable - in addition to efficiency via automation - new types of innovation and creativity, rather than simply enhancing and supporting traditional methods. </p>
                                                        <p className="description">One aspect of digital transformation is the concept of 'going paperless' or reaching a 'digital business maturity' affecting both individual businesses and whole segments of society, such as government, mass communications, art, health care and science.</p>
                                                        <p className="description">Accelerate your digital transformation with our expert team. We integrate the latest technology and best practices to help you overcome your most significant technical challenges.</p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                        <li>Risus commodo viverra</li>
                                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Application development</h2>
                                                        <p className="description">WiseHelix provides a range of application development services from web, mobile, middleware to integration tailored to your needs and requirements.</p>
                                                        <p className="description">There are several different approaches to application development: some take a more structured, engineering-based approach to developing software, whereas others may take a more incremental approach, where software evolves as it develops piece-by-piece. One system development methodology is not necessarily suitable for use by all projects. </p>
                                                        <span className="subtitle">Microsoft Power Apps </span>
                                                        <h5 className="title">Create a low or no-code application</h5>
                                                        <p className="description">Power Apps is a suite of apps, services, connectors, and data platforms that provide a rapid application development environment to build custom apps for your business needs. Using Power Apps, you can quickly build custom business apps that connect to your business data stored either in the underlying data platform (<a href="https://docs.microsoft.com/en-us/powerapps/maker/data-platform/data-platform-intro" target="_blank">Common Data Service</a>) or various online and on-premises data sources (SharePoint, Microsoft 365, Dynamics 365, SQL Server, and so on).</p>
                                                        <p className="description">A strong focus on quality assurance ensures a solution isn’t just delivered cost-effectively but is also maintainable and scalable to meet your evolving business requirements. </p>
                                                    </div>
                                                    {/* <ul className="list-style--1 mt--30">
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> Digital Marketing going to.</li>
                                                        <li><FiCheck /> Marketing Agency passage of.</li>
                                                        <li><FiCheck /> Seo Friendly you are going.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Application development-02-03.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Finance-04.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Finance</h2>
                                                        <p className="description">Automate and modernise your global financial operations with Dynamics 365 Finance. Monitor performance in real-time, predict outcomes and make data-driven decisions to drive agility and business growth.</p>
                                                        <h5 className="title">Unify and automate your financial processes >></h5>
                                                        <p className="description">Support any business model, automate billing processes from an opportunity to renew, and get actionable insights to maximise productivity and financial performance. Integrate core business processes with financials and automate standard tasks.</p>
                                                        <h5 className="title">Reduce your operational expenses >></h5>
                                                        <p className="description">Minimise costs and optimise spending across business geographies with process automation, budget control, and financial planning and analysis. </p>
                                                        <h5 className="title">Decrease financial complexity and risk >></h5>
                                                        <p className="description">Easily manage your financial operations and adapt quickly to changing local and global financial requirements with a flexible, guided, rules-based chart of accounts and dimensions. Manage frequently changing regulatory requirements with a no-code configuration service that simplifies regulatory and tax reports, e-invoicing, and payments. </p>
                                                        <h5 className="title">Enhance your financial decision making with AI >></h5>
                                                        <p className="description">Assess the health of your business, improve financial controls, optimise cash flow, and make strategic decisions faster to drive agility and growth by using real-time, unified global financial reporting, embedded analytics, and predictive insights </p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                        <li>Risus commodo viverra</li>
                                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Supply Chain Management</h2>
                                                        <h5 className="title">Resolve product quality issues and accelerate time to market </h5>
                                                        <p className="description">Accelerate innovation and respond quickly to quality issues, changing customer specifications and obsolete parts to ensure compliance and mitigate delays.</p>
                                                        <h5 className="title">Gain planning agility to fulfil customer demand </h5>
                                                        <p className="description">Predict demand using AI and deliver products on time by planning supply and production near real-time, ensuring the right resources are in the right place.</p>
                                                        <h5 className="title">Optimise stock and logistics</h5>
                                                        <p className="description">Improve delivery by using predictive analytics to optimise and automate stock, warehousing, fulfilment, material sourcing, and supply chain logistics.</p>
                                                        <h5 className="title">Innovate with intelligent manufacturing operations </h5>
                                                        <p className="description">Build agile factories and manufacturing processes with predictive technologies, IoT, and mixed reality to improve throughput, quality, and delivery while reducing costs.</p>
                                                        <h5 className="title">Maximise asset uptime and lifespan </h5>
                                                        <p className="description">Reduce equipment downtime, improve overall equipment effectiveness (OEE), and maximise longevity by performing proactive maintenance.</p>
                                                    </div>
                                                    {/* <ul className="list-style--1 mt--30">
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> Digital Marketing going to.</li>
                                                        <li><FiCheck /> Marketing Agency passage of.</li>
                                                        <li><FiCheck /> Seo Friendly you are going.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Supply Chain Management-05.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Power Platform-06.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Power Platform</h2>
                                                        <p className="description">The ‘Power Platform’ is a collective term for three Microsoft products: Power BI, PowerApps and Power Automate (previously known as Flow). </p>
                                                        <p className="description">They provide the means to help people easily manipulate, surface, automate and analyse data and can be used with Office 365 and Dynamics 365 (as well as other third-party apps and other Microsoft services).</p>
                                                        <h5 className="title">Build apps without coding >></h5>
                                                        <p className="description">Start with a canvas or model-driven app, automate processes with Power Automate, and make your apps smarter with AI Builder and Power Virtual Agents. </p>
                                                        <p className="description">Move from reading and understanding a Power BI report or dashboard to create and publish your own. </p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                        <li>Risus commodo viverra</li>
                                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Our service</span> */}
                                                        <h2 className="title">Support and upgrade</h2>
                                                        <p className="description">We understand how important for you to maintain your current system and upgrade to the latest Dynamics 365 version.</p>
                                                        <h5 className="title">Microsoft Dynamics support >></h5>                                                        
                                                        <p className="description">Our dedicated support team will actively work with you to:<br/>
                                                        Keep your current system running and secure<br/>
                                                        Support any customisation required for and by business<br/>
                                                        Provide training and support to various teams and growth of your business<br/></p>
                                                        <h5 className="title">Upgrade >></h5>
                                                        <p className="description">At a certain point, every business needs to adopt the latest technology and upgrade legacy system to support a growing organisation. Our expert team will help you achieve upgrade seamlessly and cost-effective way.</p>
                                                        <p className="description">The overall upgrade process can be visualised as three overarching phases: Analyse, Execute, and Validate.</p>
                                                        <h5 className="title">Analyze  >></h5>
                                                        <p className="description">The activities in the Analyze phase help you estimate the effort that is required for the upgrade. They also help you prepare a project plan. These activities can be done before you buy Finance and Operations. They will help you make an informed purchase decision by providing a data point about the effort and resources that you will require.</p>
                                                        <h5 className="title">Execute >></h5>
                                                        <p className="description">During the Execute phase, you work through the tasks that you planned during the Analyze phase. To move to the Execute phase, you must purchase Finance and Operations apps, and you must have available resources that can work on the upgrade.</p>
                                                        <h5 className="title">Validate >></h5>
                                                        <p className="description">When you enter the Validate phase, you will have available environments that include your upgraded custom code and upgraded data. This phase describes the process of validating and testing that the upgraded environment works as desired. It also describes the process of preparing for go-live.</p>

                                                    </div>
                                                    {/* <ul className="list-style--1 mt--30">
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> Digital Marketing going to.</li>
                                                        <li><FiCheck /> Marketing Agency passage of.</li>
                                                        <li><FiCheck /> Seo Friendly you are going.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/Support and upgrade-07.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}






                
                {/* Start Pricing Tbale Area  */}
                {/* <div className="rn-pricing-table-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our Budget Plan</span>
                                    <h2 className="title">Pricing Plan</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Free</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Business</h4>
                                            <div className="pricing">
                                                <span className="price">$45</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">$99</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 */}
                {/* End Pricing Tbale Area  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;